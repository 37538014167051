<template>
  <c-flex
    :d="['none', 'flex']"
    my="1rem"
    mx="0"
    flex-shrink="0"
    pos="sticky"
    top="100px"
    h="fit-content"
    transition-duration="300ms"
    flex-grow="1"
  >
    <c-flex
      flex-direction="column"
      pr="16px"
      w="100%"
      gap="16px"
    >
      <template v-for="menu in sideMenu">
        <c-flex
          :key="menu.name"
          flex-direction="column"
          w="100%"
        >
          <c-button
            variant="ghost"
            d="flex"
            flex-direction="row"
            align-items="center"
            justify-content="start"
            pl="1rem"
            :pr="menu.isActive ? '1rem' : '8px'"
            :background="open ? (menu.isActive ? '#F2F2F2' : 'transparent') : '#F2F2F2'"
            :border-left="open ? (menu.isActive ? '8px solid #008C81' : '8px solid transparent') : (menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2')"
            border-radius="8px"
            h="60px"
            @click="handleChangeRoute(menu)"
          >
            <c-image
              class="icon"
              :alt="menu.name"
              :src="menu.icon"
              w="24px"
              h="24px"
            />
            <c-text
              v-if="open"
              font-size="16px"
              font-weight="400"
              font-family="Roboto"
              :color="menu.isActive ? '#008C81' : '#555555'"
              ml="12px"
            >
              {{ menu.name }}
            </c-text>
          </c-button>
          <c-button-group
            v-if="menu?.children?.length >= 1"
            :d="open ? (isOpenMenu?.[menu?.id] ? 'flex' : 'none') : 'none'"
            flex-direction="column"
            mt="8px"
            mx="auto"
            w="100%"
            pl="1rem"
            :pr="menu.isActive ? '1rem' : '8px'"
          >
            <c-button
              v-for="child in menu.children "
              :key="child.name"
              as="router-link"
              :to="child.route"
              variant="ghost"
              mx="auto"
              d="flex"
              w="100%"
              px="16px"
              flex-direction="row"
              align-items="center"
              justify-content="start"
              :border-bottom="child.isActive ? '1px solid #008C81' : '1px solid #C4C4C4'"
              border-radius="0px"
              mb="8px"
            >
              <c-text
                font-size="16px"
                :color="child.isActive ? '#008C81' : '#888888'"
                font-family="Roboto"
                font-weight="400"
              >
                {{ child.name }}
              </c-text>
            </c-button>
          </c-button-group>
        </c-flex>
      </template>
    </c-flex>
  </c-flex>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SideMenu',
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpenMenu: {
        akun: false,
      },
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
      role: (s) => s.auth.user?.role,
    }),
    sideMenu() {
      const self = this
      return [
        {
          id: 'beranda',
          name: 'Beranda',
          route: '/',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-home-active.svg')
            } else {
              return require('@/assets/icons/icon-home-grey.svg')
            }
          },
          get isActive() {
            return self.$route.path === '/'
          },
        },
        {
          id: 'pemantauan',
          name: 'Pemantauan',
          route: { name: 'client.monitoring' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-monitor-active.svg')
            } else {
              return require('@/assets/icons/icon-monitor-grey.svg')
            }
          },
          get isActive() {
            return self.$route.name === 'client.monitoring'
          },
        },
        {
          id: 'panduan_makan',
          name: 'Panduan Makan',
          route: { name: 'client.meal-plan' },
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-meal-plan-active.svg')
            } else {
              return require('@/assets/icons/icon-meal-plan-grey.svg')
            }
          },
          get isActive() {
            return self.$route.name.includes('client.meal-plan')
          },
        },
        {
          id: 'akun',
          name: 'Akun',
          get icon() {
            if (this.isActive) {
              return require('@/assets/icons/icon-account-circle-active.svg')
            } else {
              return require('@/assets/icons/icon-account-circle-grey.svg')
            }
          },
          route: () => this.toggleOpenProfile(),
          get isActive() {
            return self.$route.name.includes('client.profile')
          },
          children: [
            {
              id: 'akun-akun',
              name: 'Akun',
              route: { name: 'client.profile.account' },
              get isActive() {
                return self.$route.name === 'client.profile.account'
              },
            },
            {
              id: 'akun-riwayat_program',
              name: 'Riwayat Program',
              route: { name: 'client.profile.programHistory' },
              get isActive() {
                return self.$route.name === 'client.profile.programHistory'
              },
            },
            {
              id: 'akun-riwayat_transaksi',
              name: 'Riwayat Transaksi',
              route: { name: 'client.profile.historyTransaction' },
              get isActive() {
                return self.$route.name === 'client.profile.historyTransaction'
              },
            },
            {
              id: 'akun-bahasa',
              name: 'Bahasa',
              route: { name: 'client.profile.language' },
              get isActive() {
                return self.$route.name === 'client.profile.language'
              },
            },
            {
              id: 'akun-pusat-bantuan',
              name: 'Pusat Bantuan',
              route: { name: 'client.profile.helpcenter' },
              get isActive() {
                return self.$route.name === 'client.profile.helpcenter'
              },
            },
          ],
        },
      ]
    },
  },
  methods: {
    handleChangeRoute(menuItem) {
      if (typeof menuItem.route === 'function') {
        menuItem.route()
        const handleToggleOpenMenu = () => {
          if (!this.isOpenMenu[menuItem.id]) {
            this.isOpenMenu[menuItem.id] = true
            return
          }
          this.isOpenMenu[menuItem.id] = false
        }
        handleToggleOpenMenu()
        return
      }
      if (this.$route.name === 'client.index') {
        this.$router.push(menuItem.route)
      } else {
        this.$router.replace(menuItem.route)
      }
    },
    toggleOpenProfile() {
      this.$router.push({
        name: 'client.profile',
      }).catch(()=>{})
      if (!this.open) {
        this.$emit('updateOpen', true)
        this.isOpenProfile = true
      } else {
        this.isOpenProfile = !this.isOpenProfile
      }
    },
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "d": ['none', 'flex'],
      "my": "1rem",
      "mx": "0",
      "flex-shrink": "0",
      "pos": "sticky",
      "top": "100px",
      "h": "fit-content",
      "transition-duration": "300ms",
      "flex-grow": "1",
      "z-index": "20"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "pr": "16px",
      "w": "100%"
    }
  }, _vm._l(_vm.menus, function (menu) {
    return _c('c-box', {
      key: menu.name,
      attrs: {
        "mb": "16px"
      }
    }, [_c('c-button', {
      class: menu.className,
      attrs: {
        "variant": "ghost",
        "as": "router-link",
        "replace": "",
        "to": menu.route,
        "d": "flex",
        "flex-direction": "row",
        "align-items": "center",
        "justify-content": "start",
        "pl": "1rem",
        "pr": "8px",
        "background": _vm.open ? menu.isActive ? '#F2F2F2' : 'transparent' : '#F2F2F2',
        "border-left": _vm.open ? menu.isActive ? '8px solid #008C81' : '8px solid transparent' : menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2',
        "border-radius": "8px",
        "h": "60px"
      }
    }, [_c('c-box', {
      attrs: {
        "width": "24px",
        "height": "24px",
        "color": menu.isActive ? '#008C81' : '#555'
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": menu.icon,
        "height": "100%",
        "width": "100%",
        "fill": "currentColor"
      }
    })], 1), _vm.open ? _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "font-family": "Roboto",
        "color": menu.isActive ? '#008C81' : '#555555',
        "ml": "12px"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")]) : _vm._e()], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./nav-bottom.vue?vue&type=template&id=137ed840&scoped=true&"
import script from "./nav-bottom.vue?vue&type=script&lang=js&"
export * from "./nav-bottom.vue?vue&type=script&lang=js&"
import style0 from "./nav-bottom.vue?vue&type=style&index=0&id=137ed840&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137ed840",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CGrid: require('@chakra-ui/vue').CGrid})
